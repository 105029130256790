import { Trans } from '@lingui/macro';
import { NextPage } from 'next';
import React from 'react';

import { Section } from '@apps/web/components/molecules/section/Section';
import { AppLayout } from '@apps/web/components/organisms/app-layout/AppLayout';
import { FooterType, NavigationType } from '@apps/web/lib/sanity/types';
import { Button } from '@canify/ui-button';
import { NextIcon } from '@canify/ui-icon';

export const NotFoundPageTemplate: NextPage<{
	footer: FooterType;
	navigation: NavigationType;
}> = ({ footer, navigation }) => {
	return (
		<AppLayout
			offsetHeader={false}
			navigation={navigation}
			footer={footer}
			colorScheme={'blue'}
			className={'flex-1 flex flex-col justify-center bg-brand-blue text-white'}
		>
			<Section paddingVerticalSize="none" className="bg-brand-blue pt-40 pb-20">
				<h1 className="h1 mt-7 mb-7 max-w-md lg:max-w-xl">
					<Trans id="404.headline">We lost this page</Trans>
				</h1>
				<div>
					<p className={'body-large max-w-md my-7'}>
						<Trans id="404.text">
							We´re really sorry we couldn’t find the page you were looking for! Let´s find a better place for
							you to go.
						</Trans>
					</p>

					<Button size="large" type="filled" href="/" iconRight={NextIcon}>
						<Trans id="404.label">Return home</Trans>
					</Button>
				</div>
			</Section>
		</AppLayout>
	);
};

export default NotFoundPageTemplate;
