import { ThemeProvider } from '@emotion/react';
import type { GetStaticPropsContext, GetStaticPropsResult } from 'next';
import { groq } from 'next-sanity';
import React from 'react';

import NotFoundPageTemplate from '@apps/web/components/templates/NotFoundPage';
import { footerExpansion, navigationExpansion } from '@apps/web/lib/sanity/groqExpansions';
import { getClient } from '@apps/web/lib/sanity/sanity.server';
import { FooterType, NavigationType } from '@apps/web/lib/sanity/types';
import { muiTheme } from '@apps/web/styles/muiTheme';

interface ResolvedData {
	footer: FooterType;
	navigation: NavigationType;
}

export default function PageNotFound({ data }: { data: ResolvedData }) {
	return (
		<ThemeProvider theme={muiTheme}>
			<NotFoundPageTemplate navigation={data.navigation} footer={data.footer} />
		</ThemeProvider>
	);
}

export async function getStaticProps(ctx: GetStaticPropsContext<{ slug: string[] }>): Promise<
	GetStaticPropsResult<{
		translations: Record<string, string>;
		data: ResolvedData;
	}>
> {
	const { locale, preview = false } = ctx;
	const client = getClient(preview);

	const footer = await client.fetch<FooterType>(
		groq`*[_type == "footer" && _lang == "${locale}"][0] {${footerExpansion}}`,
	);

	const navigation = await client.fetch<NavigationType>(
		groq`*[_type == "navigation" && _lang == "${locale}"][0] {${navigationExpansion}}`,
	);

	const { messages: translations } = await import(`../translations/locales/${locale}/messages.po`);

	return {
		props: {
			translations,
			data: {
				footer,
				navigation,
			},
		},
		revalidate: 900, // Revalidate at most once every 15 min.
	};
}
