import { tailwindConfig } from '@canify/theme';
import { createTheme } from '@mui/material/styles';

export const muiTheme = createTheme({
	palette: {
		primary: {
			main: tailwindConfig.theme.colors['action-blue'].DEFAULT,
		},
	},
	typography: {
		fontFamily: tailwindConfig.theme.fontFamily.body.join(', '),
	},
});
